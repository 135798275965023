/* eslint-disable global-require */
// @flow
// eslint-disable-next-line global-require
/* -----------------Third parties--------------- */
import API, { graphqlOperation } from '@aws-amplify/api';
import Storage from '@aws-amplify/storage';
import { Network } from 'relay-runtime';
import EnvironmentIDB from 'react-relay-offline/lib/runtime/EnvironmentIDB';
import { Store, Environment, RecordSource } from 'react-relay-offline';

import {
  RelayLazyLogger,
} from '@adeira/relay-runtime';
import { Buffer } from 'buffer';
/* -----------------Configs--------------- */
import { amplifyStorage } from '../config/amplify-config';

Storage.configure(amplifyStorage);
const customPrefix = {
  public: '',
};

const uploadFile = async (
  uploadables: *,
  imagePath: string,
) => {
  const {
    id,
    base64String,
  } = uploadables;
  const binaryString = Buffer.from(base64String.replace(/^data:image\/\w+;base64,/, ''), 'base64');
  const type = base64String.split(';')[0].split('/')[1];
  const fileName = `${imagePath}/${id}`;

  await Storage.put(fileName, binaryString, {
    contentEncoding: 'base64',
    contentType: `image/${type}`,
    customPrefix,
  });
};
async function fQuery(operation, variables, _, uploadables) {
  if (operation.name === 'activateBundleTrialMutation'
   || operation.name === 'createCheckoutMutation') {
    if ((uploadables && uploadables.execute)) {
      return API.graphql(graphqlOperation(operation.text, variables));
    }
    return null;
  }
  if (uploadables && uploadables.file) {
    await uploadFile(uploadables.file, uploadables.imagePath);
  }
  return API.graphql(graphqlOperation(operation.text, variables));
}

function subscribe(operation, variables) {
  return API.graphql(graphqlOperation(operation.text, variables)).map(({ value }) => value);
}

const fetcher = fQuery;
// TODO:Make this work typeof __DEV__ !== 'undefined' ||
if (process.env.NODE_ENV) {
  // const RelayNetworkLogger = createRelayNetworkLogger(
  //   RelayNetworkLoggerTransaction,
  // );
  // fetcher = RelayNetworkLogger.wrapFetch(fQuery, () => '');
  // const { installRelayDevTools } = require('relay-devtools');
  // installRelayDevTools();
}
const network = Network.create(fetcher, subscribe);

let relayEnvironment;
function createLocalStorageEnvironment() {
  const recordSource = new RecordSource();
  const store = new Store(recordSource);
  const environment = new Environment({
    network,
    store,
  });
  return environment;
}

function createIndexedDB(records) {
  const recordSourceOptions = {
    initialState: records,
    mergeState: (restoredState, initialState = {}) => {
      if (!restoredState) {
        return initialState;
      }
      if (restoredState && restoredState['0']) {
        // test
        const newStat = {
          ...restoredState,
          // $FlowFixMe
          0: {
            ...restoredState['0'],
            text: 'changed',
          },
        };
        return newStat;
      }
      return restoredState;
    },
  };
  const idbOptions = undefined;
  const environment = EnvironmentIDB.create(
    {
      network,
      log: RelayLazyLogger,
    },
    idbOptions,
    recordSourceOptions,
  );
  return environment;
}
export const manualExecution = false;
function createWebEnvironment(options: * = {}) {
  const { records = {} } = options;

  const createEnvironment = indexed => {
    const environment = indexed
      ? createIndexedDB(records)
      : createLocalStorageEnvironment();
    environment.setOfflineOptions({
      // start: async mutations => {
      //   // optional
      //   console.log('start offline', mutations);
      //   return mutations;
      // },
      // finish: async (mutations, error) => {
      //   // optional
      //   console.log('finish offline', error, mutations);
      // },
      // onExecute: async mutation => {
      //   // optional
      //   console.log('onExecute offline', mutation);
      //   return mutation;
      // },
      // onComplete: async o => {
      //   // optional
      //   console.log('onComplete offline', o);
      //   return true;
      // },
      // onDiscard: async o => {
      //   // optional
      //   console.log('onDiscard offline', o);
      //   return true;
      // },
      // onPublish: async offlinePayload => {
      //   // optional
      //   console.log('offlinePayload', offlinePayload);
      //   return offlinePayload;
      // },
    });
    return environment;
  };

  if (typeof window === 'undefined') {
    return createEnvironment(false);
  }

  // reuse Relay environment on client-side
  if (!relayEnvironment) {
    relayEnvironment = createEnvironment(true);
  }

  return relayEnvironment;
}
export {
  // eslint-disable-next-line import/prefer-default-export
  createWebEnvironment,
};
