// @flow

const getTokens = () => ({
  mixpanelToken: process.env.MIXPANEL_SECRET_TOKEN,
  gaToken: process.env.GA_SECRET_TOKEN,
  fbToken: process.env.FB_SECRET_TOKEN,
  gtmToken: process.env.GTM_KEY,
});

export {
  // eslint-disable-next-line import/prefer-default-export
  getTokens,
};
