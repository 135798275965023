// @flow
/* -----------------Third parties--------------- */
import Cookies from 'js-cookie';

const key = {
  loggedIn: 'loggedIn',
  userIconUrl: 'userIconUrl',
  userId: 'userId',
  loginSource: 'loginSource',
  migrated: 'migrated',
  redirectUrl: 'redirectUrl',
  socialAuthType: 'socialAuthType',
};
type Keys = $Keys<typeof key>
const store = (k: Keys, value: any) => {
  Cookies.set(k, value);
};

const retrieve = (k: Keys) => Cookies.get(k);

const authenticated = (k: Keys) => {
  const parsedKey = retrieve(k);
  return parsedKey === 'true';
};

// TODO:This must be used everywhere to check if logged in or not from cookie
const isAuthenticated = () => {
  const parsedKey = retrieve(key.loggedIn);
  return parsedKey === 'true';
};
export {
  store,
  retrieve,
  key,
  authenticated,
  isAuthenticated,
};
