// @flow

// /* -------------------Global----------------------- */
import { v4 as uuidv4 } from 'uuid';
import awsExports from './src/config/aws-exports';
/* -----------------Constants--------------- */
// eslint-disable-next-line import/no-cycle
import { empty } from './constants';

/* -----------------Types--------------- */
import type {
  Term,
  Image,
} from './types';

const isEmptyString = (x: any) => (
  typeof x === 'string'
    && (x.trim().length === 0
        || x === empty.name
        || x === empty.draft)
);

// TODO: Rename `formatCount` or something
const formatLabel = (text: string, count: number) => `${text}${count !== 1 ? 's' : ''}`;

const getAcronym = (input: string) => input.split(' ')
  .map(word => (word !== 'and' ? word[0] : null))
  .join('').toUpperCase();

const isEmptyList = (list: any) => {
  if (!list || (list && list.length === 0)) {
    return true;
  }
  return false;
};
const isEmpty = (x: any) => x === null
    || x === undefined
    || (typeof x === 'object' && Object.keys(x).length === 0)
    || isEmptyString(x);

const normalizeName = (name: ? string) => {
  if (typeof name === 'string') {
    let norm = name.trim();
    norm = norm.replace(/\s+/g, ' ');
    if (norm === empty.name) {
      norm = '';
    }
    return norm;
  }

  return null;
};

const titleCase = (s: string) => {
  const [c, ...rest] = s.split('');
  return `${c.toUpperCase()}${rest.join('')}`;
};

const timeStamp = () => new Date().getTime();

const stringTrim = (s: string) => s.replace(/ /g, '');

const parseTime = (time: ? string) => (time ? Date.parse(time) : new Date());

/** returns sum of all the numbers in the given array
 *
 * @param {input} array to iterate over
 * @returns sum of all the numbers
 *
 */
const sum = (input: number[]): number => (
  input.reduce((acc, cur) => acc + cur, 0)
);

/**
 *
 * @param {array to iterate over} input
 * @param {should float be enabled} floatEnable
 *
 * @returns average of the given arrray
 */
const average = (
  input: number[],
  floatEnable ?: boolean,
): number => {
  const floatFactor = floatEnable ? 1.0 : 1;
  return (sum(input) * floatFactor) / input.length;
};

type CounterResult < C > = {
    [keys: C]: number
};

function counter < T, C >(
  collection: T[],
  categorizer: (input: T) => C,
): CounterResult<C> {
  const initial = ({}: CounterResult<C>);
  return collection.reduce(
    (acc, current) => {
      const category = categorizer(current);
      const count = acc[category] || 0;
      acc[category] = count + 1;
      return acc;
    },
    initial,
  );
}

function createBatches < I >(
  items: Array<I>,
  limitCount: number,
): Array<Array<I>> {
  const appendToBatch = (batches: Array<Array<I>>, item: I) => {
    let lastBatch = batches.pop() || [];
    if (lastBatch.length === limitCount) {
      batches.push(lastBatch);
      lastBatch = [];
    }
    lastBatch.push(item);
    batches.push(lastBatch);
    return batches;
  };
  return items.reduce(appendToBatch, []);
}

const spacing = (times: number) => 8 * times;
const generateRandomUUID = () => uuidv4();

const extractExtensionFromBase64 = (
  base64String: string,
): string => base64String.split(';')[0].split('/')[1];

const getS3ImageUrl = (fileId: string) => {
  const region = awsExports.Region;
  const host = awsExports.MediaStorage;
  return `https://${host}.s3.${region}.amazonaws.com/images/${fileId}`;
};

const getS3ContentImageUrl = (fileId: string) => {
  const region = awsExports.Region;
  const host = awsExports.MediaStorage;
  return `https://${host}.s3.${region}.amazonaws.com/content/${fileId}`;
};

const shouldRenderFacts = (lastTerm: ? Term) => {
  if (!lastTerm || isEmpty(lastTerm.facts)) {
    return false;
  }
  return lastTerm.facts.some(
    ({ fact: { name, image } }) => !isEmpty(name) || !isEmpty(image),
  );
};

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : null;
}

const getRandomImage = (images: Image[]): Image => {
  const itemImage = images[
    Math.floor(Math.random() * images.length)];

  return itemImage;
};

const isEmailValid = (email ?: string) => {
  if (!email) {
    return false;
  }
  const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
  return regex.test(email);
};

const isInputFieldActive = () => {
  const { activeElement } = document;
  const inputs = ['input', 'textarea'];
  return activeElement && inputs.indexOf(activeElement?.tagName.toLowerCase()) !== -1;
};

export {
  shouldRenderFacts,
  average,
  counter,
  createBatches,
  extractExtensionFromBase64,
  generateRandomUUID,
  getS3ImageUrl,
  getRandomImage,
  isEmpty,
  isEmptyString,
  normalizeName,
  parseTime,
  stringTrim,
  sum,
  timeStamp,
  spacing,
  titleCase,
  hexToRgb,
  isEmptyList,
  getAcronym,
  formatLabel,
  getS3ContentImageUrl,
  isEmailValid,
  isInputFieldActive,
};
