// @flow
/* -----------Types---------------- */
import type {
  Term,
  Fact,
  Image,
  StudySet,
  AccessTypes,
  ContentTypes,
  Owner,
} from '../types';

import {
  accessType,
  contentType,
} from '../types';

type AvailablePrompts = { title: string, newPrompt: boolean }[]
const screenType = {
  createStudySet: 'Create study set',
  editStudySet: 'Edit study set',
  addTerm: 'Add term',
  editTerm: 'Edit term',
  addFact: 'Add fact',
  editFact: 'Edit fact',
  editTitle: 'Title',
  editDescription: 'Description',
};

type ScreenTypes = $Values<typeof screenType>

const section = {
  title: 'title',
  description: 'description',
};

type Sections = $Keys<typeof section>

type SectionSelectAction = (
  sectionType: Sections,
  content: string
) => void

type SaveChanges = string => void;

type EditorButtonSaveAction = (
  studySet: StudySet
) => void

// TODO: Move to common
type ContentInput = {
  name?: ?string,
  description?: ?string,
  accessType?: ?AccessTypes,
  created?: ?string,
  ownerId?: ?string,
  contentType?: ?ContentTypes,
}

type ImageInput = {
  url?: ?string,
  name?: ?string,
  width?: ?number,
  height?: ?number,
  copyRight?: ?string,
  id: string,
}

type ImageData = {
  id: string,
  base64String: string,
  copyRight?: ?string,
}

type UpdateFactInput = {
  name: string,
  description?: ?string,
  prompt?: ?string,
}
type FactInput = {
  termId: string,
  name: string,
  prompt?: ?string,
  imageData?: ?ImageData,
  ordinal?: number
};

type UpdateTermInput = {
  name: string,
  description: string,
}

type TermInput = {
  studySetId: string,
  name: string,
  imagesData?: ?(ImageData[]),
  ordinal?: number
};

type StudySetDraft = {
  id: string,
  name: ?string,
  description: ?string,
  accessType: ?AccessTypes,
  created: string,
  ownerId: string,
  contentType: ContentTypes,
  created: string,
  owner: Owner,
  isFlashCardsOnly: boolean
}

type CreateStudySetState = {
  currentStudySetId: ?string,
  shouldPublish: boolean,
  currentTerm?: ?Term,
  availablePrompts?: ?AvailablePrompts,
  showPromptEditor: boolean
}

const publishMode = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
};

type PublishModes = $Keys<typeof publishMode>

export {
  section,
  screenType,
  accessType,
  contentType,
  publishMode,
};

export type {
  ContentInput,
  CreateStudySetState,
  EditorButtonSaveAction,
  Fact,
  FactInput,
  UpdateFactInput,
  UpdateTermInput,
  PublishModes,
  Image,
  ImageData,
  ImageInput,
  SaveChanges,
  ScreenTypes,
  Sections,
  SectionSelectAction,
  StudySetDraft,
  Term,
  TermInput,
  AvailablePrompts,
};
