/* -----------------Third parties--------------- */
import Auth from '@aws-amplify/auth';

// eslint-disable-next-line import/extensions
import awsExports from 'common/src/config/aws-exports.json';

const graphqlHeader = async () => {
  try {
    const token = (await Auth.currentSession()).idToken.jwtToken;
    return { Authorization: token };
  } catch (e) {
    return {};
  }
};

const amplifyStorage = {
  AWSS3: {
    bucket: awsExports.MediaStorage,
    region: awsExports.Region,
  },
};

const buildConfig = (scope, redirectSignIn, redirectSignOut) => ({
  domain: awsExports.UserPoolDomain,
  scope: scope || null,
  redirectSignIn: redirectSignIn || null,
  redirectSignOut: redirectSignOut || null,
  responseType: 'token',
});

export { buildConfig, graphqlHeader, amplifyStorage };
