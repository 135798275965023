// @flow
/* -----------------Third parties--------------- */
import awsExports from 'common/src/config/aws-exports.json';
import { graphqlHeader, buildConfig } from './amplify-config';
import {
  authenticated,
  key,
} from '../../common/authutils';

// eslint-disable-next-line import/extensions

const UserPoolClients = JSON.parse(awsExports.UserPoolClients || null);

const redirectSignIn = UserPoolClients.webClientCallbackURLs;
const redirectSignOut = UserPoolClients.webClientLogoutURLs;

const setUpConfig = () => {
  const isAuthenticated = authenticated(key.loggedIn);
  return {
    Auth: {
      userPoolId: awsExports.UserPoolId,
      userPoolWebClientId: awsExports.UserPoolClientIdWeb,
      identityPoolId: awsExports.IdentityPoolId,
      region: awsExports.Region,
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    aws_appsync_graphqlEndpoint: awsExports['API-Endpoint'],
    aws_appsync_region: awsExports.Region,
    aws_appsync_authenticationType: isAuthenticated ? 'AWS_IAM' : 'API_KEY',
    ...(!isAuthenticated ? {
      aws_appsync_apiKey: awsExports['API-Key'],
    } : {}),
    graphql_headers: graphqlHeader,
    oauth: {
      ...buildConfig(UserPoolClients.webClientScope,
        redirectSignIn,
        redirectSignOut),
    },
  };
};

export default setUpConfig;
