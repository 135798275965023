/* Imports */
// @flow
import {
  getTokens,
} from './config';

const {
  mixpanelToken,
} = getTokens();

/* ======== SETUP ========= */
const getMixpanelInstance = async () => {
  const { default: mix } = await import('mixpanel-browser');
  return mix;
};

const init = async () => {
  const mixpanel = await getMixpanelInstance();
  mixpanel.init(mixpanelToken);
};

const getDistinctId = async () => {
  const mixpanel = await getMixpanelInstance();
  const distinctId = mixpanel.get_distinct_id();
  return distinctId;
};

/* ======== AUTHENTICATION ========= */
const trackLogin = async (uid: string, socialAuthType?: string) => {
  const mixpanel = await getMixpanelInstance();
  // Mixpanel
  mixpanel.track('login');
  mixpanel.identify(uid);

  if (socialAuthType === 'Google') {
    window.dataLayer.push({
      event: 'login',
      user_id: uid,
      method: 'Google',
    });
  }
};

const registerUserProperties = async (params: *) => {
  const mixpanel = await getMixpanelInstance();
  const { userId, email, username } = params;
  mixpanel.register(userId, {
    uuid: userId,
    email,
    username,
  });
  mixpanel.people.set({
    uuid: userId,
    email,
    username,
  });
};

const trackLogout = async () => {
  const mixpanel = await getMixpanelInstance();
  mixpanel.track('logout');
  mixpanel.reset();
};

/* ======== PAGE VIEWS ========= */
const trackPageView = async (params: * = {}) => {
  // Mixpanel
  const {
    authenticated,
    pageType,
  } = params;
  const mixpanel = await getMixpanelInstance();
  // Mixpanel
  mixpanel.register_once({ 'First time': true }); // Tracks first-time visitors
  mixpanel.track('page viewed', { ...params }, () => {
    setTimeout(mixpanel.register({
      'First time': false,
    }), 500); // Eliminates first-time assumption
  });
  if (authenticated) {
    mixpanel.people.union('Pages visited', pageType);
    mixpanel.people.increment({
      'Pages viewed': 1,
      'Pages viewed web': 1,
    });
  }
  // Google analytics
  window.dataLayer.push({
    event: 'page_view',
    authenticated,
    pageType,
    page_title: params.title,
    id: params.id,
    tagType: params.tagType,
  });
};

/* ======== SHARING ========= */
const trackSendInvite = async (params: * = {}) => {
  const mixpanel = await getMixpanelInstance();
  mixpanel.track('invite_sent', { ...params });
  if (params.authenticated) {
    mixpanel.people.increment('Invites sent', params.count);
  }
  // gtag('event', 'share');
};

/* ======== PREMIUM ========= */
const trackCustomEvent = async (params: * = {}) => {
  const {
    eventName,
    ...rest
  } = params;
  const restParams = rest ? { ...rest } : {};
  const mixpanel = await getMixpanelInstance();
  mixpanel.track(eventName, restParams);
  window.dataLayer.push({
    event: eventName,
    ...rest,
  });
};

/* ======== GENERIC EVENT ========= */
const trackEvent = async (eventName: string, params: * = {}) => {
  const mixpanel = await getMixpanelInstance();
  mixpanel.track(eventName, { ...params });
};

/* ======== EXPERIMENTS ========= */
const trackExperiment = async (params: * = {}) => {
  const { experimentId, cohort } = params;
  const mixpanel = await getMixpanelInstance();
  // https://help.mixpanel.com/hc/en-us/articles/360038439952#add-experiments-to-an-implementation
  mixpanel.track('$experiment_started', {
    'Experiment name': experimentId,
    'Variant name': cohort,
  });
};

const mergeProfileProperties = async (name: string, value: string) => {
  const mixpanel = await getMixpanelInstance();
  mixpanel.people.union(name, value);
};

const updateUserProperties = async (properties: *) => {
  const mixpanel = await getMixpanelInstance();
  Object.keys(properties).forEach(key => {
    mixpanel.people.set({
      [key]: properties[key],
    });
  });
};
export {
  init,
  registerUserProperties,
  trackPageView,
  trackLogin,
  trackLogout,
  trackSendInvite,
  getDistinctId,
  trackCustomEvent,
  trackEvent,
  trackExperiment,
  mergeProfileProperties,
  updateUserProperties,
};
