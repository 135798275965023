// @flow

const baseUrl = 'https://memorang.com/';
const baseUrlPSI = 'https://psi.memorang.com/';
const baseUrlWithoutSlash = 'https://memorang.com';
const missionUrl = `${baseUrl}mission`;
const teamUrl = `${baseUrl}team`;
const legalUrl = `${baseUrl}legal`;
const storeUrl = `${baseUrl}store`;
const partnersUrl = `${baseUrl}partners`;
const mentorsUrl = `${baseUrl}mentors`;
const testimonialsUrl = `${baseUrl}testimonials`;

export {
  baseUrl,
  missionUrl,
  teamUrl,
  legalUrl,
  storeUrl,
  partnersUrl,
  mentorsUrl,
  testimonialsUrl,
  baseUrlWithoutSlash,
  baseUrlPSI,
};
