// @flow
/* -----------------Globals--------------- */
import React, {
  useEffect,
  useState,
} from 'react';
import * as Sentry from '@sentry/nextjs';

/* -----------------Third parties--------------- */
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';

/* -----------------Configs--------------- */
import setUpAmplifyConfig from '@components/config/amplify-web-config';

/* -----------------Actions--------------- */
import { isAuthenticated } from '../actions/auth';
import {
  store,
  key,
} from '../../common/authutils';

/* -----------------Props--------------- */
type AuthContextValue = {
  isAuthenticated: boolean,
  user: *,
  refreshAuth: *,
  viewerId: string,
  err: boolean,
  token: ?string,
  isEmailVerified: boolean,
  loading: boolean
}

/* -----------------Constants--------------- */
const initialContextValue: AuthContextValue = {
  isAuthenticated: false,
  user: null,
  refreshAuth: null,
  viewerId: '',
  err: false,
  token: null,
  isEmailVerified: false,
  loading: false,
};

/* -----------------Context--------------- */
const AuthContext = React.createContext<AuthContextValue>(
  initialContextValue,
);

const setSentryUser = (user) => {
  const logicalIdKey = 'custom:logicalId';
  const {
    username,
    attributes: { [logicalIdKey]: id, email, preferred_username: pUserName },
  } = user;

  Sentry.setUser({
    id,
    email,
    username: pUserName || username,
  });
};

const AuthContainer = ({ children }: *) => {
  const [user, setUser] = useState(null);
  const [err, setError] = useState(false);
  const [token, setToken] = useState(null);
  const [isEmailVerified, setEmailVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!loading) {
      if (user) {
        store(key.loggedIn, 'true');
        store(key.userId, user.attributes['custom:logicalId']);
        Auth.configure(setUpAmplifyConfig());
        API.configure(setUpAmplifyConfig());
      } else {
        store(key.loggedIn, 'false');
      }
    }
  }, [loading, user]);
  const refreshAuth = async () => {
    try {
      setLoading(true);
      const result = await isAuthenticated();
      if (result) {
        const {
          signInUserSession: {
            accessToken: {
              jwtToken,
            },
          },
          attributes: {
            email_verified: emailVerified,
            'custom:hasFbLogin': hasFbLogin,
            'custom:hasGoogleLogin': hasGoogleLogin,
            'custom:hasAppleLogin': hasAppleLogin,
          },
        } = result;
        setToken(jwtToken);
        const finalEmailVerified = emailVerified || (hasFbLogin || hasGoogleLogin || hasAppleLogin);
        setEmailVerified(finalEmailVerified);
        setSentryUser(result);
      }
      setUser(result);
    } catch {
      setError(true);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { refreshAuth(); }, []);

  const contextValue = {
    isAuthenticated: !(!user),
    user,
    err,
    refreshAuth,
    token,
    isEmailVerified,
    loading,
    viewerId: user ? user.attributes['custom:logicalId'] : '',
  };
  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContainer;

export { AuthContext };
