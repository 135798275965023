/* eslint-disable react/no-danger */
// @flow
/* -----------------Globals--------------- */
import React, { useEffect } from 'react';
import 'react-medium-image-zoom/dist/styles.css';

/* -----------------Third parties--------------- */
import API from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import {
  JS,
} from '@aws-amplify/core';
import { NextSeo, BreadcrumbJsonLd } from 'next-seo';

/* -----------------Nextjs--------------- */
import dynamic from 'next/dynamic';
import Head from 'next/head';
import {
  useRouter,
} from 'next/router';
import Script from 'next/script';

/* -----------------CSS--------------- */
import './input.css';

/* -----------------Hooks--------------- */
import useDarkMode from 'use-dark-mode';

/* -----------------UI--------------- */
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';

/* -----------------Analytics & Crashlytics--------------- */
import {
  init as initAnalytics,
} from '../components/src/analytics/tracking';

/* -----------------Configs--------------- */
import setUpAmplifyConfig from '@components/config/amplify-web-config';
import theme from '../components/src/theme';
import {
  getTokens,
} from '../components/src/analytics/config';

/* -----------------Context--------------- */
import PageWithContext from '@components/common/PageContext';

/* -----------------HOC--------------- */
import AuthContainer from '@components/hocs/auth';

/* -----------------Constants--------------- */
import {
  getMetadata,
  buildAdditionalMeta,
} from '../components/src/common/seoutils';
import {
  authenticated,
  key,
} from '../components/src/common/authutils';
import {
  RelayEnvironmentProvider,
} from 'react-relay';
import webRelayEnvironment from '@components/env/relay-environment-web';

// eslint-disable-next-line func-names
JS.browserOrNode = function () {
  const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
  const isNode = typeof process !== 'undefined'
    && process.versions != null
    && process.versions.node != null;
  return {
    isBrowser,
    isNode,
  };
};
Auth.configure(setUpAmplifyConfig());
API.configure(setUpAmplifyConfig());

const ReduxContainer = dynamic(() => import('@components/common/ReduxContainer'), { ssr: false });
const Chrome = dynamic(() => import('../components/src/components/chrome'), { ssr: false });
const ZendeskWidget = dynamic(() => import('../components/src/widgets/ZendeskChat'), { ssr: false });
/* -----------------Props--------------- */
type Props = {
  Component: *,
  pageProps: *,
}
const whiteListedPathsForZendesk = ['flashcards', 'questions', 'folder', 'session'];

const {
  gtmToken,
} = getTokens();
/* -----------------Component--------------- */
const MemorangApp = (props: Props) => {
  const {
    Component,
    pageProps,
  } = props;
  const { meta } = pageProps;
  const isAuthenticated = authenticated(key.loggedIn);
  const {
    asPath,
  } = useRouter();
  const searchString = asPath && asPath.split('/') ? asPath.split('/')[1] : null;
  const shouldInitZendesk = isAuthenticated ? true
    : !whiteListedPathsForZendesk.includes(searchString);

  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
  }, []);

  // Hide zendesk chat widget in study session, otherwise shows
  useEffect(() => {
    if (window && window.zE) {
      if (window.location.pathname.indexOf('session') > -1
      || window.location.pathname.indexOf('study') > -1) {
        window.zE(() => {
          window.zE.hide();
        });
      } else {
        window.zE('webWidget', 'show');
      }
    }
  }, [pageProps]);

  useEffect(() => {
    initAnalytics();
  }, [isAuthenticated]);

  const Chromed = () => (
    <Chrome>
      <Component {...pageProps} />
    </Chrome>
  );

  const {
    description,
    title,
    url,
    opengraph,
    noindex,
    courseJsonLd,
    datePublished,
    dateModified,
    authorName,
    jsonLdBreadcrumbs,
  } = getMetadata(meta);
  const {
    value,
  } = useDarkMode(false);
  const themeObject = theme(value);
  const additionalMetaProps = {
    datePublished,
    dateModified,
    authorName,
  };
  const additionalMetaTags = buildAdditionalMeta(additionalMetaProps);
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        additionalMetaTags={additionalMetaTags}
        canonical={url}
        openGraph={opengraph}
        noindex={noindex}
      />
      {jsonLdBreadcrumbs && jsonLdBreadcrumbs.length ? (
        <BreadcrumbJsonLd
          itemListElements={jsonLdBreadcrumbs}
        />
      ) : null}
      <Head>
        <meta charSet="utf-8" />
        {courseJsonLd ? (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: courseJsonLd,
            }}
          />
        ) : null}
        {/* Use minimum-scale=1 to enable GPU rasterization */}
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
      </Head>
      {/* Google Tag Manager - Global base code */}
      {gtmToken ? (
        <>
          <Script
            strategy="afterInteractive"
          >
            {'(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':'
              + 'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],'
              + 'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src='
              + '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);'
              + `})(window,document,'script','dataLayer',"${gtmToken}");`}
          </Script>
        </>
      ) : null}
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
        rel="stylesheet"
      />
      <MuiThemeProvider theme={themeObject}>
        <CssBaseline />
        <RelayEnvironmentProvider environment={webRelayEnvironment}>
          <AuthContainer>
            <ReduxContainer>
              <PageWithContext Component={Chromed} pageProps={pageProps} />
            </ReduxContainer>
            {shouldInitZendesk ? <ZendeskWidget /> : null}
          </AuthContainer>
        </RelayEnvironmentProvider>
      </MuiThemeProvider>
    </>
  );
};

export default MemorangApp;
