// @flow
/* -----------------Types--------------- */
type CommonThemeColor = {
  light?: ?string,
  dark?: ?string,
  contrastText?: ?string,
  main: string
}
type Colors = {
  light: {
    primary: CommonThemeColor,
    secondary: CommonThemeColor
  },
  dark: {
    primary: CommonThemeColor,
    secondary: CommonThemeColor
  }
}
// Text
const text = {
  light: 'rgba(255,255,255,0.87)',
  dark: 'rgba(0,0,0,0.87)',
};

const commonPrimaryColors = {
  contrastText: text.light,
  opacity: 'rgba(255, 255, 255, 0.87)',
  background: 'rgba(155, 213, 255, 0.2)',
};

const commonSecondaryColors = {
  contrastText: text.light,
  background: 'rgba(155, 213, 255, 0.2)',
};

const memorang = {
  primary: {
    light: '#4ebbad',
    main: '#22aa99',
    dark: '#17766b',
    ...commonPrimaryColors,
  },
  secondary: {
    light: '#9bd5ff',
    main: '#63a4ff',
    dark: '#1c76cb',
    ...commonSecondaryColors,
  },
};

// This function only should be used if all the color code are same for light, main, dark
// If they are different, Please specify them in the theme itself
const buildCommonThemeObject = (color: CommonThemeColor) => {
  const {
    light, main, dark, contrastText,
  } = color;
  return {
    light: light || main,
    main,
    dark: dark || main,
    contrastText: contrastText || text.light,
  };
};

// TODO: This should work for building any theme?
const buildWhiteLabelTheme = (colors: Colors) => {
  const getTheme = (paletteType) => {
    const { [paletteType]: { primary, secondary } } = colors;
    return {
      primary: {
        ...commonPrimaryColors,
        ...buildCommonThemeObject(primary),
      },
      secondary: {
        ...commonSecondaryColors,
        ...buildCommonThemeObject(secondary),
      },
    };
  };

  return {
    light: getTheme('light'),
    dark: getTheme('dark'),
  };
};

const rxpocketcoach = buildWhiteLabelTheme({
  light: {
    primary: {
      main: '#163A6A', // Dark fuscia
    },
    secondary: {
      main: '#163A6A', // Scholar blue
    },
  },
  dark: {
    primary: {
      main: '#A9C7FF', // Emerald,
    },
    secondary: {
      main: '#A9C7FF', // Azure white
      contrastText: text.dark,
    },
  },
});
const therapyed = buildWhiteLabelTheme({
  light: {
    primary: {
      main: '#8a1e04',
    },
    secondary: {
      main: '#16283b',
    },
  },
  dark: {
    primary: {
      main: '#F78F1E',
    },
    secondary: {
      main: '#F78F1E',
    },
  },
});

const naplex = buildWhiteLabelTheme({
  light: {
    primary: {
      main: '#00598A',
    },
    secondary: {
      main: '#00598A',
    },
  },
  dark: {
    primary: {
      main: '#00598A',
    },
    secondary: {
      main: '#00A7FA',
    },
  },
});

const psi = buildWhiteLabelTheme({
  light: {
    primary: {
      main: '#9E0442', // Dark fuscia
    },
    secondary: {
      main: '#002A4E', // Scholar blue
    },
  },
  dark: {
    primary: {
      main: '#3DAF8D', // Emerald,
    },
    secondary: {
      main: '#E1EEF9', // Azure white
      contrastText: text.dark,
    },
  },
});

const themes = {
  memorang,
  therapyed,
  naplex,
  psi,
  rxpocketcoach,
  sfe: psi,
};

export default themes;
