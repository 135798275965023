// @flow

/* -----------------Constants--------------- */
import { baseUrl } from 'common/urlConstants';

/* -----------------Types--------------- */
import type {
  Meta,
  AdditonalMetaProperties,
} from '../components/head/types';

/* -----------------Utils--------------- */
import {
  openGraphFromMeta,
} from '../components/head/jsonLdUtils';

const defaultTitle = 'Memorang';
const defaultDesc = 'Personalized study tools that empower learners to master and retain knowledge for any subject';
const defaultUrl = baseUrl;

const defaultMeta = {
  title: defaultTitle,
  description: defaultDesc,
  url: defaultUrl,
};

const buildAdditionalMeta = (metaProps: AdditonalMetaProperties) => {
  const {
    authorName,
    datePublished,
    dateModified,
  } = metaProps;
  return [...(datePublished ? [{
    property: 'datePublished',
    content: datePublished,
  }] : []), ...(dateModified ? [{
    property: 'dateModified',
    content: dateModified,
  }] : []), ...(authorName ? [{
    property: 'authorName',
    content: authorName,
  }] : [])];
};
const constructMeta = (metadata?: Meta) => {
  if (metadata) {
    const {
      title,
      description,
      url,
      noindex,
      courseJsonLd,
      dateModified,
      datePublished,
      authorName,
      jsonLdBreadcrumbs,
    } = metadata;
    return {
      title,
      description,
      url,
      opengraph: openGraphFromMeta(metadata),
      noindex,
      courseJsonLd,
      dateModified,
      datePublished,
      authorName,
      jsonLdBreadcrumbs,
    };
  }
  return {
    ...defaultMeta,
    noindex: false,
    courseJsonLd: null,
    dateModified: null,
    datePublished: null,
    authorName: null,
    jsonLdBreadcrumbs: [],
    opengraph: openGraphFromMeta(defaultMeta),
  };
};

const getMetadata = (meta?: Meta) => {
  const metadata = constructMeta(meta);
  return metadata;
};

export {
  buildAdditionalMeta,
  getMetadata,
};
