// @flow

import type {
  DistributionTypes,
} from '../types';

const THRESHOLD_MASTERY_NORMAL_SESSION = 85;
const THRESHOLD_MASTERY_SCHEDULED_SESSION = 85;
const RANGE_LOW = [0, 50];
const RANGE_MEDIUM = [50, 85];
const RANGE_HIGH = [85, 101];
const NEXT_BATCH_BREAKPOINT = 3;
const ALERT_NO_PERMISSION = 'Session creation failed because all the contents are either private or premium.';
const encouragements = {
  positive:
    [
      'You\'re doing great!',
      'Great job!',
      'You did it!',
      'Wow, that was awesome!',
      'Well done!',
      'Impressive!',
      'You are amazing!',
      'You are awesome!',
      "You're on a roll!",
      'Quite the performance!',
      'Fantastic effort!',
      'Keep it up!',
      'Knowledge courses through your veins!',
    ],
  negative: [
    'Hang in there',
    'You can do it',
    "Keep trying and you'll get there",
    'Improvement comes one step at a time',
    'You only learn from wrong answers',
    'Persistence is key',
    'Practice makes perfect',
    'Keep going strong',
  ],
};

const messagesSession = [
  {
    icon: 'tune',
    text: 'Configuring session',
  },
  {
    icon: 'brain',
    text: 'Analyzing mastery',
  },
  {
    icon: 'folder-download-outline',
    text: 'Loading content',
  },
];

const masteredAllItemsPrompt = "All of the content you've selected is already mastered, but you can always review things in a randomized or original order";
// for study unauth in product pages flow
const dummyMemoryBreakdown = {
  longTerm: 42,
  mediumTerm: 30,
  shortTerm: 20,
  notAnswered: 8,
};
const captionFlashcardsOnly = 'This content has been configured by the author to only be studied as "flashcards"';

const timeContextModes = {
  GAMETYPE: 'GAMETYPE',
  ITEM: 'ITEM',
  USER: 'USER',
};
// Time per practice question, additional review time in seconds
const timePerQuestion = 72;
export const timePerCST = 7 * 60;
const timePerQuestionStudy = 10;
// TODO: to be learned
// write a query and fetch upfront
const timePerFactMap = {
  FLIP: 15,
  QUIZ: 5,
  MATCH: 10,
  CST: 7 * 60,
  FLASHCARD: 15,
};
// review time in seconds per mode
// nothing to review after answer in match mode
// TODO: real-data to replace this later
const reviewTimeMap = {
  FLIP: 10,
  QUIZ: 20,
  QUESTION: 45,
  MATCH: 0,
  CST: 0,
  FLASHCARD: 10,
};
const defaultTimes = {
  image: 4, // 4 seconds to zoom, interpret an image
  interface: 2, // 2 seconds to move mouse/finger to tap
};

// TODO: to be learned
const numAttemptsPerMastery = (type: DistributionTypes) => {
  const repetitions = {
    none: 4,
    low: 5,
    medium: 3,
    high: 0.1,
  };
  return repetitions[type];
};

const ItemSliderBoundaryValues = {
  DEFAULT: 100,
  QUESTION_SET: 50,
  LOWER_BOUND: 5,
};

export {
  THRESHOLD_MASTERY_NORMAL_SESSION,
  THRESHOLD_MASTERY_SCHEDULED_SESSION,
  encouragements,
  RANGE_HIGH,
  RANGE_MEDIUM,
  RANGE_LOW,
  masteredAllItemsPrompt,
  messagesSession,
  ALERT_NO_PERMISSION,
  NEXT_BATCH_BREAKPOINT,
  dummyMemoryBreakdown,
  captionFlashcardsOnly,
  reviewTimeMap,
  timeContextModes,
  timePerFactMap,
  timePerQuestion,
  timePerQuestionStudy,
  defaultTimes,
  numAttemptsPerMastery,
  ItemSliderBoundaryValues,
};
