// @flow
/* -----------------Types--------------- */
import type {
  ContentTypes,
} from './content/types';
import {
  contentType,
  type ItemVariants,
} from './content/types';
import type {
  Publisher,
} from './store/types';
import type {
  SessionMode,
} from './session/types';

type ProgressData = {
  target: string[],
  answered: string[],
  skipped: string[],
};

const sortingType = {
  ORIGINAL: 'ORIGINAL',
  ALPHABETICAL: 'ALPHABETICAL',
  MASTERY: 'MASTERY',
};
type DistributionCategories = {
  none: number,
  low: number,
  medium: number,
  high: number,
};

const sortOptions = ['ORIGINAL', 'MASTERY', 'ALPHABETICAL'];

type SortingTypes = $Keys<typeof sortingType>;
type DistributionTypes = $Keys<DistributionCategories>;

type SortItem = {
  name: SortingTypes,
  isAscending: boolean
};
type UniqueAnswers = {
  [itemId: string]: {
    score: number,
    high: boolean,
    previouslyHigh: boolean,
    contentId: string
  },
};
const permission = {
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  PARTIAL_VIEW: 'PARTIAL_VIEW',
  SHARE_TO_EDIT: 'SHARE_TO_EDIT',
  SHARE_TO_PARTIAL_VIEW: 'SHARE_TO_PARTIAL_VIEW',
  SHARE_TO_VIEW: 'SHARE_TO_VIEW',
  REMOVE_ACCESS: 'REMOVE_ACCESS',
  REMOVE_SHARING: 'REMOVE_SHARING',
};
type Permissions = $Keys<typeof permission>

const folderType = {
  Favorites: 'Favorites',
  Recent: 'Recent',
  Created: 'Created',
  StudyPacks: 'Study-Packs',
  'Study-Packs': 'Study-Packs',
  Folder: 'Folder',
  Search: 'Search',
  Shared: 'Shared',
  Schedule: 'Schedule',
  Tasks: 'Tasks',
};

type FolderTypes = $Values<typeof folderType>;

const itemType = {
  flashcard: 'flashcard',
  quizQuestion: 'quizQuestion',
  practiceQuestion: 'practiceQuestion',
  match: 'match',
};

const contentVariant = {
  dashboard: 'dashboard',
  schedule: 'schedule',
  scheduleview: 'scheduleview',
  profile: 'profile',
  search: 'search',
  addtofolder: 'addtofolder',
  schedulesearch: 'schedulesearch',
  // studyset , folder or questions set
  content: 'content',
  smartfolder: 'smartfolder',
};

type ContentVariants = $Keys<typeof contentVariant>
const reducerLabel = {
  flip: 'flipReducer',
  quiz: 'choiceReducer',
  questionset: 'choiceReducer',
  match: 'matchReducer',
};

type Time = {
  hour: number,
  minutes: number,
  isAm: boolean,
}

type Image = {
  id: string,
  url?: ?string,
  name?: ?string,
  width?: ?number,
  height?: ?number,
  backgroundColor?: ?string,
}
type Owner = {
  name: string,
  username: string,
  iconUrl?: ?string,
  email: string
};

type LeaderBoardUser = {
  avatar: Image,
  username: string,
  time: number,
  items?: number
}

const accessType = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
  PREMIUM: 'PREMIUM',
};

type AccessTypes = $Keys<typeof accessType>

type EmptyAction = () => void

const learningOrder = {
  LINEAR: 'LINEAR',
  OPTIMIZED: 'OPTIMIZED',
  SHUFFLED: 'SHUFFLED',
};

type LearningOrders = $Keys<typeof learningOrder>

const studySessionContextType = {
  SAMPLE: 'SAMPLE',
  SELECTION: 'SELECTION',
};

type StudySessionContextType = $Keys<typeof studySessionContextType>

type StudyContent = {
  contentId: string,
  contentType: ContentTypes,
  isFlashCardsOnly?: ?boolean
}
type SessionContext = {
  contextType: StudySessionContextType,
  contents: StudyContent[]
}

type ItemTypes = $Values<typeof itemType>
type ReducerLabels =$Values<typeof reducerLabel>

const dashboardCardVariant = {
  create: 'create',
  find: 'find',
  purchase: 'purchase',
  schedule: 'schedule',
  shared: 'shared',
  studyset: 'studyset',
  folder: 'folder',
  browse: 'browse',
};
type DashboardCardVariants = $Keys<typeof dashboardCardVariant>

const mode = {
  Flashcards: 'Flashcards',
  Questions: 'Questions',
  'Study Sets': 'Study Sets',
};
const createMode = {
  createStudySet: 'Study Set',
  createFolder: 'Folder',
  createSchedule: 'Schedule',
};

const actionMode = {
  Find: 'Find',
  Create: 'Create',
  Purchase: 'Purchase',
};

type CreateModes = $Values<typeof createMode>
type ActionModes = $Keys<typeof actionMode>
type Modes = $Keys<typeof mode>

type Score = {
  value: number
}
type FactMetadata = {
  currentScore: Score,
  previousScore: Score
}

const goalCardType = {
  questions: 'questions',
  facts: 'facts',
};

type GoalCardTypes = $Keys<typeof goalCardType>

const learningMode = {
  flip: 'flip',
  quiz: 'quiz',
  match: 'match',
  questionSet: 'questionSet',
  questions: 'question',
  simulations: 'cst',
  flashcards: 'flashcard',
};

const gameCompletionMode = {
  studySet: 'Study Session',
  questionset: 'Practice Questions',
  recommendedFlashcards: 'Recommended Flashcards',
  recommendedQuestions: 'Recommended Questions',
};

type GameCompletionModes = $Values<typeof gameCompletionMode>

type LearningMode = $Keys<typeof learningMode>;

const studyTypes = {
  PRACTICE: 'PRACTICE',
  STUDY: 'STUDY',
};
type StudyType = $Keys<typeof studyTypes>;

const learningModeCaps = {
  FLIP: 'FLIP',
  QUIZ: 'QUIZ',
  MATCH: 'MATCH',
  CST: 'CST',
  FLASHCARD: 'FLASHCARD',
};
type LearningModeCaps = $Keys<typeof learningModeCaps>;

const confidenceType = {
  low: 'low',
  medium: 'medium',
  high: 'high',
};
type ConfidenceType = $Keys<typeof confidenceType>;

const confidence = {
  low: 0,
  medium: 50,
  high: 100,
};

type Confidence = $Values<typeof confidence>;

const learningProgressType = {
  none: 'none',
  low: 'low',
  medium: 'medium',
  high: 'high',
};

const learningProgressTypes = [
  learningProgressType.none,
  learningProgressType.low,
  learningProgressType.medium,
  learningProgressType.high,
];

type LearningProgressType = $Keys<typeof learningProgressType>;
type Distribution<T> = { [LearningProgressType]: T };

type ConfidenceSelectAction = (
  confidence: Confidence,
  currentIndex?: number
) => void

type PageInfo = {
  hasNextPage: boolean,
  hasPreviousPage: boolean,
  endCursor: string,
  startCursor: string
};
type FolderContentsCount = {
  numFolders: number,
  numStudySets: number,
  numQuestionSets: number
}

type FolderContentsItemsCount = {
  numTerms: number,
  numFacts: number,
  numQuestions: number
}

type FolderItemsCount = {
  numFolders: number,
  numStudySets: number,
  numQuestionSets: number,
  numTerms: number,
  numFacts: number,
  numQuestions: number,
};
type Edge<N> = {
  cursor: string,
  node: N,
};

type Connection<N> = {
  nextToken: string,
  edges: Edge<N>[],
  pageInfo: PageInfo
};
type TagDistribution = {
  id: string,
  type: string,
  label: string,
  distribution: Distribution<number>,
  childTags: TagDistribution[]
}

  type Tag = {
    label: string,
    title?: string,
    distribution: Distribution<number>,
    id: string,
    count: number,
    locked: boolean
  }
type ContentMetaData = {
  isFavorited: boolean,
  distribution: Distribution<number>,
  permissions: Permissions[],
  tagDistribution?: ?TagDistribution[]
}

type TagDetail = {
  id: string,
  type: string,
  label: string,
  count: number,
  childTags?: TagDetail[],
  locked?: boolean,
  accessType: AccessTypes,
  metadata: {
     distribution: Distribution<number>,
  }
}
type Content = {
  id: string,
  numericId: number,
  name: string,
  learningContentType: string,
  description: string,
  owner: Owner,
  updated: string,
  accessType: AccessTypes,
  contentType: ContentTypes,
  isFlashCardsOnly?: boolean,
  /* eslint-disable no-use-before-define */
  contents?: Connection<ContentDetails>,
  bundleId: ?number,
  created: string,
  isPublished: boolean,
  distributionCount: number,
  contentsCount?: FolderContentsCount,
  contentsItemsCount?: FolderContentsItemsCount,
  itemsCount: FolderItemsCount,
  icon: Image,
  isActive: boolean,
  publisher: Publisher,
  sharedMembers?: ?SharedMemberDetails[],
  tags?: TagDetail[]
}

type ModifiedContent = {
  contentType: ContentTypes,
  isFlashCardsOnly?: boolean,
  id: string,
  name: string,
  tags?: TagDetail[]
}
type ContentDetails = {
  id?: ?string,
  content: Content,
  metadata: ContentMetaData,
}
type ModifedContentDetails = {
  id?: ?string,
  content: ModifiedContent,
  metadata: ContentMetaData,
}
type Folder = {
  id: string,
  numericId: number,
  name: string,
  description?: string,
  contentType: ContentTypes,
  accessType: AccessTypes,
  numStudySets?: number,
  owner: Owner,
  contentsCount: FolderContentsCount,
  contentsItemsCount: FolderContentsItemsCount,
  contents: Connection<ContentDetails>,
};

type FolderDetails = {
  id: string,
  folder: Folder,
  metadata: ContentMetaData
}

type User = {
  id: string,
  name: string,
  iconUrl: string,
  created: string,
  drafts: FolderDetails,
  authored: FolderDetails,
  favorites: FolderDetails,
  purchased: FolderDetails,
}

type SharedMemberDetails = {
  user: User,
  permissions: Permissions[]
}

type onImageCrossSelect = (
  selectedImageId: string,
) => void

type TextChangeAction = (
  text: string
) => void

type LearningModeAction = (Distribution<number>, string) => void

type PerformanceType = {
    goal: number,
    answered: number,
    skipped: number,
    accuracy: number,
    timePerAnswer: number,
  }

type PerformanceWithProgress = {
  accuracy: number,
  timePerAnswer: number,
}
const performanceDataKey = {
  mode: 'mode',
  goal: 'goal',
  studied: 'studied',
  answered: 'answered',
  learned: 'learned',
  time: 'time',
  accuracy: 'accuracy',
  actions: 'actions',
};

type PerformanceDataKeys = $Keys<typeof performanceDataKey>
type PerformanceData = {
  key: PerformanceDataKeys,
  title: string,
  icon: string,
  value: string
}

type CardId = {
  termId: string,
  factId: string,
};

type StudySetAnswerInput = {
  sessionId: string,
  studySetId: string,
  cardIds: CardId[],
  confidence: number,
  learningMode: string,
  clientTime: string,
};

type Fact = {
  id: string,
  name?: string,
  description?: string,
  prompt?: ?string,
  image?: ?Image,
};

type FactDetails = {
  id: string,
  fact: Fact,
  metadata: FactMetadata
}

type TermMetadata = {
  isFavorited: boolean,
  distribution: Distribution<number>,
  permissions: Permissions[]
}
// TODO: Change type to have edges

type FD = {
  fact: Fact
}
type TermModified = {
  id: string,
  name?: ?string,
  description?: ?string,
  facts: FD[],
  images?: ?Image[],
}
type Term = {
  id: string,
  name?: ?string,
  description?: ?string,
  facts: FactDetails[],
  images?: ?Image[],
  ordinal?: number
}
type TermDetails = {
  term: Term,
  metadata: TermMetadata
}

type Terms = {
  edges: Edge<TermDetails>[],
  pageInfo: PageInfo
}

type Prefix = $Keys<typeof learningMode>;

type TimesChanged = []
type EventContext = {
  sessionId: string,
  contextId: string,
  time: string,
  timeTaken: number,
  numDistractors: number,
  timezone?: string,
  isCorrect: boolean,
  timesChanged?: TimesChanged,
  scheduleId?: ?string
}

type ItemsCount = {
  numTerms: number,
  numFacts: number,
  numQuestions: number
}
type StudySet = {
  id: string,
  terms: Terms,
  name: string,
  isPublished: boolean,
  description: string,
  numericId: number,
  accessType: AccessTypes,
  contentType: ContentTypes,
  owner: Owner,
  itemsCount: ItemsCount,
  icon?: ?Image,
  sharedMembers?: ?SharedMemberDetails[],
  isFlashCardsOnly: boolean,
}

type StudySetDetails = {
  id: string,
  studySet: StudySet,
  metadata: ContentMetaData
}

type QuestionSetDetails = {
  id: string,
  questionSet: StudySet,
  metadata: ContentMetaData
}
type EventAnswer = {
  eventContext: EventContext,
  questionId: string,
  answerId: string,
  confidence?: number,
  score: number,
  chosenAnswerId?: string,
  sessionMode?: ?SessionMode,
  correctness: number,
  selectedChoiceIds?: string[],
  variant?: ItemVariants
}

type NextContext = {
  params: {
    slug: string[],
    category: string,
    id: string,
  },
  query: string,
}

type SessionMeta = {
    [key: ?string]: {
      lastTime: number,
      numSuccessAttempts: number,
      numTotalAttempts: number,
    }
};

const learningModeIcon = {
  flip: 'cards-outline',
  quiz: 'format-list-checks',
  match: 'checkerboard',
  questionSet: 'format-list-checks',
  simulations: 'call-split',
  questions: 'question-set',
};

type AreaChartData = {
  date: string,
  masteryCount?: number,
  timeTaken?: number
}

type TickValues = Array<Date>

export {
  learningProgressTypes,
  learningProgressType,
  accessType,
  contentType,
  folderType,
  dashboardCardVariant,
  itemType,
  learningOrder,
  mode,
  permission,
  reducerLabel,
  sortingType,
  sortOptions,
  studySessionContextType,
  createMode,
  actionMode,
  confidence,
  learningMode,
  learningModeIcon,
  gameCompletionMode,
  confidenceType,
  goalCardType,
  performanceDataKey,
  contentVariant,
  studyTypes,
};
export type {
  AccessTypes,
  ActionModes,
  AreaChartData,
  Confidence,
  ConfidenceSelectAction,
  ConfidenceType,
  Connection,
  Content,
  ContentDetails,
  ContentMetaData,
  ContentTypes,
  ContentVariants,
  CreateModes,
  DashboardCardVariants,
  Distribution,
  DistributionTypes,
  Edge,
  EmptyAction,
  EventAnswer,
  EventContext,
  Fact,
  FactDetails,
  FactMetadata,
  Folder,
  FolderContentsCount,
  FolderContentsItemsCount,
  FolderDetails,
  FolderItemsCount,
  FolderTypes,
  GameCompletionModes,
  GoalCardTypes,
  Image,
  ItemTypes,
  LeaderBoardUser,
  LearningMode,
  LearningModeAction,
  LearningModeCaps,
  LearningOrders,
  LearningProgressType,
  Modes,
  ModifedContentDetails,
  ModifiedContent,
  NextContext,
  onImageCrossSelect,
  Owner,
  PerformanceData,
  PerformanceType,
  PerformanceWithProgress,
  Permissions,
  Prefix,
  ProgressData,
  QuestionSetDetails,
  ReducerLabels,
  Score,
  SessionContext,
  SessionMeta,
  SharedMemberDetails,
  SortingTypes,
  SortItem,
  StudySet,
  StudySetAnswerInput,
  StudySetDetails,
  StudyType,
  Term,
  TermDetails,
  TermMetadata,
  TermModified,
  Terms,
  TextChangeAction,
  TickValues,
  Time,
  UniqueAnswers,
  User,
  TagDistribution,
  TagDetail,
  Tag,
  TimesChanged,
};
