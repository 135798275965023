// @flow
/* -----------------Third parties--------------- */
import Auth from '@aws-amplify/auth';
import { detect } from 'detect-browser';

/* ------------------- Next js ------------------- */
import Router from 'next/router';

/* -----------------Analytics----------------- */
import {
  trackLogin, trackLogout, getDistinctId, registerUserProperties,
} from '../../analytics/tracking';

/* -----------------Utils--------------- */
import {
  store,
  key,
} from '../../common/authutils';

const clearCache = async () => {
  await Auth.cleanCachedItems();
};

const updateUserPlatform = async (platform: string, version: string) => {
  const user = await Auth.currentAuthenticatedUser();
  const attributes = {
    'custom:platform': platform,
    'custom:version': version,
  };
  const result = await Auth.updateUserAttributes(user, attributes);
  return result;
};

const signIn = async (username: string, password: string) => {
  const normalizedUserName = username.trim();
  const result = await Auth.signIn(normalizedUserName, password);
  const userId = result.attributes['custom:logicalId'];
  const browser = detect();
  if (browser) {
    const {
      name,
      version,
    } = browser;
    updateUserPlatform(name, version);
  }
  trackLogin(userId);

  registerUserProperties({
    userId,
    email: result.attributes.email,
    username: result.username,
  });

  return result;
};

const signUp = async (username: string, password: string, email: string) => {
  const distinctId = await getDistinctId();

  await Auth.signUp({
    username,
    password,
    attributes: {
      email,
    },
    validationData: [],
    clientMetadata: {
      mixpanelId: distinctId,
    },
  });
  const result = await signIn(username, password);
  return result;
};

const forgotPasswordVerification = async (username: string) => {
  await Auth.forgotPassword(username);
};

const forgotPasswordReset = async (username: string, code: string, newPassword: string) => {
  await Auth.forgotPasswordSubmit(username, code, newPassword);
};

const isAuthenticated = async (): any => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const promise = new Promise((resolve) => {
      if (currentSession.refreshToken && currentSession.refreshToken.token) {
        cognitoUser.refreshSession(currentSession.refreshToken, () => {
          resolve(cognitoUser);
        });
      } else {
        resolve(cognitoUser);
      }
    });
    return promise;
  } catch (e) {
    return null;
  }
};

const verifyUserEmail = async (user: any) => {
  await Auth.verifyUserAttribute(user, 'email');
};

const confirmUserEmail = async (user: any, code: string) => {
  const res = await Auth.verifyUserAttributeSubmit(user, 'email', code);
  await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });
  return res;
};

const changePassword = async (user: any, oldPassword: string, newPassword: string) => {
  const res = await Auth.changePassword(user, oldPassword, newPassword);
  return res;
};

const changeUserAttributes = async (user: any, email: string, username: string) => {
  const attributes = {};
  attributes.email = email;
  attributes.preferred_username = username;
  const res = await Auth.updateUserAttributes(user, attributes);
  await Auth.currentAuthenticatedUser({
    bypassCache: true,
  });
  registerUserProperties({
    userId: user.attributes['custom:logicalId'],
    email,
    username,
  });
  return res;
};

const signInUsingSocialAuth = async (provider: string) => {
  store(key.redirectUrl, Router.asPath);
  store(key.socialAuthType, provider);
  await Auth.federatedSignIn({ provider });
};

const signOut = async () => {
  trackLogout();
  await Auth.signOut();
  if (window.zE) {
    window.zE(() => {
      window.zE('webWidget', 'clear');
    });
  }
};

const passwordValidation = (password: string) => {
  const expression = /^[a-zA-Z0-9!@#$%^&*()_-]*$/;
  return expression.test(password);
};

export default Auth;
export {
  clearCache,
  isAuthenticated,
  signUp,
  signIn,
  signOut,
  signInUsingSocialAuth,
  forgotPasswordVerification,
  forgotPasswordReset,
  verifyUserEmail,
  confirmUserEmail,
  changePassword,
  changeUserAttributes,
  passwordValidation,
  updateUserPlatform,
};
