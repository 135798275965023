// @flow
import {
  type LearningMode,
  type Image,
} from '../types';

const itemVariant = {
  MCQ: 'MCQ',
  SATA: 'SATA',
};
type ItemVariants = $Keys<typeof itemVariant>

const contentType = {
  BUNDLE: 'BUNDLE',
  DIAGRAM_SET: 'DIAGRAM_SET',
  FOLDER: 'FOLDER',
  QUESTION_SET: 'QUESTION_SET',
  SCHEDULE: 'SCHEDULE',
  READING: 'READING',
  RECORDING: 'RECORDING',
  STUDY_SET: 'STUDY_SET',
  VIDEO: 'VIDEO',
  TEXTBOOK: 'TEXTBOOK',
  CST: 'CST',
};

type ContentTypes = $Keys<typeof contentType>

const Mode = {
  FLIP: 'FLIP',
  MATCH: 'MATCH',
  QUESTION: 'QUESTION',
  QUIZ: 'QUIZ',
};

type Modes = $Keys<typeof Mode>

type Suggestion = {
  source: string,
  correction: string,
}

type FlipMode = {
  frontOfCardContent: string,
  backOfCardContent: string,
}

type MatchMode = {
  termCardContent: string,
  factCardContent: string,
}
type QuizModeChoice = {
  name: string,
  isChosen: boolean,
  isCorrect: boolean,
}

type QuizMode = {
  question: string,
  choices: QuizModeChoice[],
}

type ModeDetailsItem = {
  [key: LearningMode]: FlipMode | QuizMode | MatchMode
}
type ModeDetails = {
  mode: Modes,
  modeDetailsItem?: ModeDetailsItem
};

type LeaderboardItemType = {
  username: string,
  xp: number,
  avatar: string,
  rank: number
}

type AchievementType = {
  title: string,
  description: string,
  points: number,
  level: number,
  events: number,
  totalEvents: number,
  usersEarned: number,
  icon: Image
}
type LeaderboardUserRecord = {
  xp: number,
  rank: number
}
export {
  contentType,
  Mode,
  itemVariant,
};
export type {
  ContentTypes,
  Suggestion,
  ModeDetails,
  MatchMode,
  QuizMode,
  FlipMode,
  Modes,
  LeaderboardItemType,
  ItemVariants,
  LeaderboardUserRecord,
  AchievementType,
};
