// @flow
/* -----------------Types--------------- */
import {
  ConnectionHandler,
} from 'relay-runtime';
import {
  sortingType,
  type SortItem,
  createMode,
  actionMode,
  confidenceType,
  contentType,
  type FolderContentsCount,
  type FolderContentsItemsCount,
  type FolderItemsCount,
  type TagDistribution,
} from './types';

import type {
  Permissions,
  Confidence,
} from './types';

/* -----------------Constants--------------- */
import { Locales } from './constants';

type PartialDistribution = {
  high: number,
  low: number,
  medium: number,
  none?: number
}

type Metadata = {
  isFavorited: boolean,
  distribution: PartialDistribution,
  permissions: Permissions[],
  tagDistribution?: ?TagDistribution[]
}

const emptyFolderCreateModes = (getContentIcon: *) => [
  {
    label: createMode.createStudySet,
    icon: getContentIcon(contentType.STUDY_SET, null, null, true),
  },
  {
    label: createMode.createFolder,
    icon: getContentIcon(contentType.FOLDER),
  },
];
const emptyFolderActionModes = [
  {
    label: actionMode.Find,
  },
  {
    label: actionMode.Create,
  },
  {
    label: actionMode.Purchase,
  },
];

const titleCase = (s: string) => {
  const [c, ...rest] = s.split('');
  return `${c.toUpperCase()}${rest.join('')}`;
};

const getSortButtonText = (currentSortType: SortItem) => {
  const descendingSuffix = currentSortType.name === sortingType.ORIGINAL ? '' : '↓';
  const sortName = currentSortType.name;
  const sortButtonText = currentSortType.isAscending
    ? `${sortName} ↑`
    : `${sortName} ${descendingSuffix}`;
  return sortButtonText;
};
const getFolderItemsCount = (
  contentCount: FolderContentsCount,
  contentItemsCount: FolderContentsItemsCount,
  // $FlowFixMe
): FolderItemsCount => ({
  ...contentCount,
  ...contentItemsCount,
});

const getContentItemsCount = (
  contentCount?: FolderContentsCount,
  contentItemsCount?: FolderContentsItemsCount,
  itemsCount: FolderItemsCount,
) => {
  const finalItemsCount = contentItemsCount && contentCount
    ? getFolderItemsCount(contentCount, contentItemsCount)
    : itemsCount;

  return finalItemsCount;
};
const getMetadata = (
  numItems?: ?number,
  metadata: Metadata,
) => {
  const {
    isFavorited,
    distribution,
    distribution: {
      high,
      low,
      medium,
    },
    permissions,
    tagDistribution,
  } = metadata;
  const none = (numItems || distribution.none || 0) - (high + low + medium);
  const dist = { ...distribution, none: Math.max(none, 0) };
  const finalMetadata = {
    isFavorited, distribution: dist, permissions, tagDistribution,
  };
  return finalMetadata;
};
const getViewerId = (user: *) => (user ? user.attributes['custom:logicalId'] : null);

const getContentRecords = (
  viewerRecord: *,
  contentName: string,
) => {
  if (viewerRecord) {
    const content = viewerRecord.getLinkedRecord(contentName);

    if (content) {
      const folder = content.getLinkedRecord('folder');
      const favConnection = folder.getLinkedRecord('contents');
      // TODO:Convert home web container to fragments
      const authcontentConnection = ConnectionHandler.getConnection(
        folder,
        'ContentList_contents',
      );
      const finalConnection = authcontentConnection || favConnection;
      if (finalConnection) {
        const edges = finalConnection.getLinkedRecords('edges');
        return {
          contentConnection: finalConnection,
          edges,
        };
      }
    }
  }
  return null;
};

const formatNumber = (num: number): string => num?.toLocaleString(Locales.EN_US);

const formatText = (count: number, label: string) => {
  const formattedCount = formatNumber(count);

  if (count === 1) return `${formattedCount} ${label}`;
  return `${formattedCount} ${label}s`;
};

const getConfidenceData = (
  confidence: Confidence,
) => {
  switch (confidence) {
    case 0:
      return {
        text: 'Low',
        iconType: confidenceType.low,
      };
    case 50:
      return {
        text: 'Medium',
        iconType: confidenceType.medium,
      };
    case 100:
      return {
        text: 'High',
        iconType: confidenceType.high,
      };
    default:
      return {
        text: 'Low',
        iconType: confidenceType.low,
      };
  }
};

const CustomIntl = {
  get timezone() {
    if (global.Localization) {
      return global.Localization.timezone;
    }
    if (typeof window !== 'undefined') {
      return window?.Intl?.DateTimeFormat().resolvedOptions().timeZone;
    }

    return 'America/Los_Angeles';
  },
};

export {
  getContentRecords,
  getViewerId,
  getMetadata,
  getSortButtonText,
  titleCase,
  emptyFolderActionModes,
  emptyFolderCreateModes,
  formatText,
  getFolderItemsCount,
  getContentItemsCount,
  getConfidenceData,
  formatNumber,
  CustomIntl,
};
