// @flow
import {
  type ContentTypes,
  contentType as cT,
} from 'common/types';

const encodeStringToSlugOld = (value: string) => value
  .toString()
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .trim()
  .replace(/\s+/g, '+') // Replace spaces with +
  .replace(/\//g, '-or-')
  .replace(/[^\w+-]+/g, '')
  .replace(/&/g, '-and-'); // Replace & with '-and-'

const encodeStringToSlug = (value: string) => value
  .toString()
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .toLowerCase()
  .trim()
  .replace(/\s+/g, '-')
  .replace(/[^\w-]+/g, '')
  .replace(/--+/g, '-');

const decodeSlugToString = (slug: string) => slug
  .replace(/\+/g, ' ') // Replace + with space
  .replace(/-or-/g, '') // Replace '-or-' with /
  .replace(/-and-/g, '&'); // Replace '-and-' with &

const constructPath = (type: string) => `/${type}/[...slug]`;
const constructAsOld = (type: string, nId: number, name?: ?string) => (name ? `/${type}/${nId}/${encodeStringToSlugOld(name)}` : `/${type}/${nId}`);
const constructAs = (type: string, nId: number, name?: ?string) => (name ? `/${type}/${nId}/${encodeStringToSlug(name)}` : `/${type}/${nId}`);

const thresholds = {
  folder: 1624623,
  questions: 496, // question set
  flashcards: 400529, // study set
  premium: 29, // bundle
};

const getAs = (type: string, numericId: number, name: ?string) => {
  if (numericId > thresholds[type]) {
    return constructAs(type, numericId, name);
  }
  return constructAsOld(type, numericId, name);
};

const getSlug = (type: string, numericId: number, value: string) => {
  if (numericId > thresholds[type]) {
    return encodeStringToSlug(value);
  }
  return encodeStringToSlugOld(value);
};

const getHrefAndPathForContent = (contentType: ContentTypes,
  numericId: number,
  name?: ?string) => {
  switch (contentType) {
    case cT.FOLDER:
      return {
        as: getAs('folder', numericId, name),
        href: constructPath('folder'),
        slug: name ? getSlug('folder', numericId, name) : '',
      };
    case cT.QUESTION_SET:
      return {
        as: getAs('questions', numericId, name),
        href: constructPath('questions'),
        slug: name ? getSlug('questions', numericId, name) : '',
      };
    case cT.STUDY_SET:
      return {
        as: getAs('flashcards', numericId, name),
        href: constructPath('flashcards'),
        slug: name ? getSlug('flashcards', numericId, name) : '',
      };
    case cT.BUNDLE:
      return {
        as: getAs('premium', numericId, name),
        href: constructPath('premium'),
        slug: name ? getSlug('premium', numericId, name) : '',
      };
    case cT.SCHEDULE:
      return {
        as: getAs('schedule', numericId, name),
        href: constructPath('schedule'),
        slug: name ? getSlug('schedule', numericId, name) : '',
      };
    default:
      return {
        as: '',
        href: '',
        slug: '',
      };
  }
};

export {
  encodeStringToSlug,
  getHrefAndPathForContent,
  encodeStringToSlugOld,
  decodeSlugToString,
};
