// @flow
import {
  titleCase,
} from './utils';
import {
  ALERT_NO_PERMISSION,
} from './session/constants';

const empty = {
  name: 'REFER IMAGE',
  draft: 'DRAFT',
};

const root = {
  dashboard: 'Dashboard',
  profile: 'Profile',
};

const alert = {
  pos: 'positive',
  neg: 'nevative',
};

const successMessages = {
  favorite: 'Added to favorites!',
  unfavorite: 'Removed from favorites.',
  remove: 'Removed content(s) successfully!',
  delete: 'Deleted content(s) successfully!',
  invite: 'Invitation sent!',
};

const errorMessages = {
  generic: 'Something went wrong, Please try again!',
};

/*
  Default appbar width size is 56 (ref: material.io)
  our logo image dimension is 948 * 300 so we set
  height as 48 ( 4 px padding on above and below appbar )
  and width ( as proportion to height ) is 151.
 */

const errorAlertConfig = (
) => ({
  title: 'Something needs fixing!',
  message: 'Please make sure that each term and its accompanying facts has both images and/or text before saving.',
  positiveButtonText: 'GOT IT',
});

const errorPublishConfig = (
) => ({
  title: 'Something went wrong!',
  message: 'There was an error publishing a set, Please try again. If the issue persists again, Please contact support',
  positiveButtonText: 'GOT IT',
});

const errorAlertGenericConfig = () => ({
  title: 'Something went wrong!',
  message: 'There was an issue when creating a schedule.',
  positiveButtonText: 'Ok',
});

const deleteAlertConfig = (
  type: string,
  callback: (buttonType: string) => void,
  actionName?: ?string,
  multiple?: ?boolean,
) => {
  const action = actionName || 'delete';
  const verb = multiple ? 'these' : 'this';
  return {
    title: `${titleCase(action)} ${type.toLowerCase()}`,
    message: `Are you sure you want to ${action} ${verb} ${multiple ? 'items' : type.toLowerCase()}?`,
    positiveButtonText: 'OK',
    negativeButtonText: 'Cancel',
    onButtonPressed: callback,
  };
};

const studySetSuccessAlertConfig = (callback: *, name: string) => ({
  title: 'Published!',
  message: `Your study set ${name} has been successfully published!`,
  positiveButtonText: 'Ok',
  onButtonPressed: callback,
});

const sessionErrorAlertConfig = (callback: *, error ?: boolean) => ({
  title: 'Error',
  message: error ? ALERT_NO_PERMISSION : 'Session creation failed, please try again',
  positiveButtonText: 'OK',
  onButtonPressed: callback,
});

const learningModeLabel = {
  none: 'Not answered',
  low: 'Needs work',
  medium: 'Almost there',
  high: 'Mastered',
};

const performanceCardAnswerType = {
  flip: 'Card',
  quiz: 'Question',
  match: 'Item',
  questionSet: 'Question',
  flashcards: 'Flashcard',
  questions: 'Question',
  simulations: 'Simulation',
};

const contentUpdateThrottleTime = 50;
const leaderboardItemsPerPage = 7;
const sessionComplete = 'Completed';
// TODO: Get this from api
const supportedSymbols = ['↑', '↓', '→', '←', '↔', '≤', '≥', '°', 'α', 'β', 'γ', 'δ',
  'ε', 'ζ', 'η', 'θ', 'ι', 'κ', 'λ', 'μ', 'ν', 'ξ', 'ο', 'π', 'ρ', 'ς', 'σ', 'τ', 'υ',
  'φ', 'χ', 'ψ', 'ω', 'Γ', 'Δ', 'Σ', 'Ω'];

const loadingDelays = {
  migration: 10e3,
};

const Locales = {
  EN_US: 'en-US',
};

export {
  contentUpdateThrottleTime,
  performanceCardAnswerType,
  sessionComplete,
  supportedSymbols,
  learningModeLabel,
  errorAlertConfig,
  deleteAlertConfig,
  studySetSuccessAlertConfig,
  leaderboardItemsPerPage,
  errorMessages,
  successMessages,
  sessionErrorAlertConfig,
  root,
  empty,
  alert,
  loadingDelays,
  errorAlertGenericConfig,
  Locales,
  errorPublishConfig,
};
