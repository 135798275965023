// @flow

/* -----------------Globals--------------- */
import React, {
  useState,
} from 'react';

/* -----------------Utils--------------- */
import { authenticated, key } from '../../common/authutils';

/* -----------------Types--------------- */
import type {
  PageTypes,
} from 'common/analytics/types';

/* -----------------Analytics--------------- */
import { trackPageView } from '../../analytics/tracking';

// constant
const pageVariant = {
  splash: 'splash',
  session: 'session',
  static: 'static',
  app: 'app',
  store: 'store',
  premium: 'premium',
  folder: 'folder',
  checkout: 'checkout',
  auth: 'auth', // Create account, log in, forgot password, etc.
};

/* -----------------Type declarations--------------- */
type PageVariant = $Keys<typeof pageVariant>;

type PageContextValue = {
  variant: PageVariant,
  setVariant: *,
  };

/* -----------------Context--------------- */
const initialContextValue = {
  variant: pageVariant.app,
  setVariant: null,
};

const PageContext = React.createContext<PageContextValue>(
  initialContextValue,
);
const isAuthenticated = authenticated(key.loggedIn);

/* -----------------Component--------------- */
const PageWithContext = ({
  Component,
}: *) => {
  const [pageContext, setPageContext] = useState(initialContextValue.variant);

  const setVariant = React.useCallback((context: *, pageType: ?PageTypes, rest?: * = {}) => {
    setPageContext(context);
    if (pageType) {
      trackPageView({
        authenticated: isAuthenticated,
        Device: 'web',
        pageType,
        ...rest,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextProp = {
    variant: pageContext,
    setVariant,
  };
  return (
    <PageContext.Provider value={contextProp}>
      <Component />
    </PageContext.Provider>
  );
};

/* -----------------exports--------------- */
export default PageWithContext;

export {
  PageContext,
  pageVariant,
};

export type {
  PageContextValue,
  PageVariant,
};
