/* eslint-disable no-case-declarations */
// @flow
/* -----------------Third parties--------------- */
import API, { graphqlOperation } from '@aws-amplify/api';

/* -----------------Types--------------- */
import type { Bundle } from 'common/store/types';
import type {
  StudySet,
  Connection,
  ContentDetails,
} from 'common/types';
import { publishMode } from 'common/create/types';
import type {
  CourseJsonLd,
  QuestionSet,
  Meta,
} from './types';
import {
  contentType as tContentType,
  type ContentTypes,
} from 'common/types';

/* --------------Constants-----------*/
import { baseUrlWithoutSlash, baseUrl } from 'common/urlConstants';

/* ------------------Utils---------------*/
import {
  getHrefAndPathForContent,
} from '@components/common/utils';
import {
  isEmpty,
  isEmptyList,
} from 'common/simpleUtils';

const bundleNameQuery = `query BundleNameQuery($numericId: Int){
         bundleDetails(numericId: $numericId) {
           bundle{
           name
           }
         }
}`;

const bundleJsonLdQuery = `query BundleJsonLdQuery($id: Int!) {
  bundleDetails(numericId: $id) {
    bundle {
              id
              name
              examName
              isActive
              description
              tagline
              accessType
              categories
              features
              numericId
              rootFolderId{
                numericId
              }
              numTrialDays
              contentsItemsCount {
                numTerms
                numFacts
                numQuestions
              }
              contentsCount {
                numStudySets
                numQuestionSets
                numFolders
              }
              publisher {
                name
              }
              icon {
                id
                name
                url
              }
                demoContents{
               id
               label
               contentType
               numericId
               availableModes
               image{
                 url
               }
               itemsCount{
                 numTerms
               }
              }
              plans {
                id
                name
                amount
                currency
                interval
                duration
              }
              media {
                id
                url
                name
              }
              testimonials {
                id
                text
              }
              contents {
                folder {
                  numericId
                  contents {
                    edges {
                      node {
                        content {
                          id
                          numericId
                          name
                          contentType
                          ... on Folder {
                            contents {
                              edges {
                                node {
                                  content {
                                    id
                                    numericId
                                    name
                                    contentType
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            metadata {
              permissions
              subscription {
                status
                endDate
                isLegacy
                hasRenewed
              }
              trial {
                status
                endDate
                isOpted
              }
            }
          
  }
}`;
const bundleDetailsCheckoutQuery = `query BundleJsonLdQuery($id: Int!) {
  bundleDetails(numericId: $id) {
    bundle {
              id
              name
              examName
              numericId
              features
              isActive
              rootFolderId{
                numericId
              }
                icon {
                id
                name
                url
              }
              numTrialDays
              plans {
                id
                name
                amount
                currency
                interval
                duration
              }
            }
  }
}`;

const bundleCategoriesQuery = `
  query BundleCategoriesQuery{
              bundleList {
                bundle{
                  name
                  description
                  numericId
                  categories
                  publisher{
                    name
                  }
                }
              }
          }`;

const questionSetQuery = `
  query QuestionSetQuery($numericId: Int!) {
    questionSetDetails(numericId: $numericId) {
      id
      metadata{
        isFavorited
      }
      questionSet {
        numericId
        name
        created
        updated
        contentType
        id
        accessType
        bundleId
        description
        icon{
          url
        }
        owner{
          name
          iconUrl
        }
        itemsCount {
          numQuestions
        }
        questions {
          edges {
            node {
              question {
                stem
                numericId
              }
            }
          }
        }
      }
    }
  }`;
const questionDetailsQuery = `
  query QuetionDetailsQuery($numericId: Int!,$questionSetNumericId: Int!) {
    questionDetails(numericId: $numericId , questionSetNumericId : $questionSetNumericId) {
      id
      question {
         id
        stem
         choices {
          id
          text
          explanation
          isCorrect
          numFirstSelections
        }
      }
    }
  }`;
const breadcrumbsQuery = `
  query BreadcrumbsQuery($contentId: ID!,$pathElement: ID) {
    breadCrumbs(contentId: $contentId ,pathElement : $pathElement ) {
        name
        id
        typ
    }
  }`;

const studySetQuery = `
  query StudySetDetailsQuery($id: Int!, $publishMode: PublishMode!) {
    studySetDetails(numericId: $id, publishMode : $publishMode) {
      id
      metadata{
        isFavorited
        distribution{
          none
        }
      }
      studySet {
        id
        created
        updated
        contentType
        numericId
        name
        accessType
        description
        isFlashCardsOnly
        bundleId
         icon{
              url
            }
        owner{
          name
          iconUrl
        }
        itemsCount{
          numTerms
          numFacts
        }
        terms(first : 10){
          edges{
            node{
              id
              term{
                id
                name
                tags{
                  id
                  label
                  type
                }
                facts{
                  fact{
                    id
                    name
                    prompt
                    image{
                      id
                      url
                      width
                      height
                    }
                  }
                }
                images{
                  id
                  url
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }`;

const folderQuery = `query FolderDetailsContainerQuery($numericId: Int) {
        folderDetails(numericId: $numericId) {
           metadata{
           isFavorited
         }
            folder {
              id
              name
              description
              accessType 
              numericId
              contentType
              owner{
                name
                iconUrl
              }
              icon{
                id
                url
              }
              bundleId
              created
              updated
              contents (first : 30) {
                edges {
                  node {
                    id
                    content {
                    id
                name
                description
                contentType
                created
                isPublished
                numericId
                accessType
                  ... on Bundle {
                  rootFolderId{
                    numericId
                  }
                  contentsItemsCount {
                    numTerms
                    numFacts
                    numQuestions
                  }
                  icon{
                    id
                    url
                  }
                  contentsCount {
                    numStudySets
                    numQuestionSets
                    numFolders
                  }
                  icon {
                    id
                    url
                  }
                  publisher {
                    id
                    name
                  }
                  updated
                }
                owner {
                  name
                  email
                  iconUrl
                }
                 ... on Folder {
                  contentsItemsCount {
                    numTerms
                    numFacts
                    numQuestions
                  }
                   icon{
                     id
                    url
                  }
                  contentsCount {
                    numStudySets
                    numQuestionSets
                    numFolders
                  }
                  updated
                }
                ... on StudySet {
                  itemsCount {
                    numTerms
                    numFacts
                  }
                   icon{
                    url
                    id
                  }
                  updated
                }
                ... on QuestionSet {
                  itemsCount {
                    numQuestions
                  }
                   icon{
                    url
                    id
                  }
                  updated
                }
                    }
                  }
                }
              }
            }
          }
        }`;

const handleStudyContents = async (contents: Connection<ContentDetails>) => {
  const { edges } = contents;
  const contentData = edges.map((edge) => {
    const {
      node: {
        content,
      },
    } = edge;
    return (content);
  });

  const parsedContents = contentData.reduce((acc, contentSet) => {
    if (contentSet.contentType === 'QUESTION_SET') {
      acc.questionSetIds.push(contentSet.numericId);
    } else if (contentSet.contentType === 'STUDY_SET') {
      acc.studySetIds.push(contentSet.numericId);
    }
    return acc;
  }, { questionSetIds: [], studySetIds: [] });

  const qSetsResults = await Promise.all(
    parsedContents.questionSetIds.map(async id => {
      const res = await API.graphql(graphqlOperation(questionSetQuery, { numericId: id }));
      return res.data.questionSetDetails.questionSet;
    }),
  );

  const sSetsResults = await Promise.all(
    parsedContents.studySetIds.map(async id => {
      const res = await API.graphql(graphqlOperation(studySetQuery,
        { id, publishMode: publishMode.PUBLISHED }));
      return res.data.studySetDetails.studySet;
    }),
  );
  return { qSetsResults, sSetsResults };
};

const getCanonicalUrl = (type: ContentTypes, numericId: number, name?: string) => {
  const {
    as,
  } = getHrefAndPathForContent(type, numericId, name);
  const url = `${baseUrlWithoutSlash}${as}`;
  return url;
};
const makeQuestionSetJsonLd = (questionSet: QuestionSet) => {
  const {
    name,
    numericId,
    owner,
    itemsCount: {
      numQuestions,
    },
  } = questionSet;

  let {
    description,
  } = questionSet;

  if (!description) {
    if (numQuestions > 0) {
      description = `Test yourself on ${name} with ${numQuestions} questions \
authored by ${owner.name} that contain detailed breakdowns and track your progress.`;
    } else {
      description = `Learn about ${name} with practice questions authored by  ${owner.name}.`;
    }
  }
  const url = getCanonicalUrl(tContentType.QUESTION_SET, numericId, name);
  const item = {
    '@context': 'https://schema.org',
    '@type': 'Course',
    name,
    description,
    url,
    provider: {
      '@type': 'Organization',
      name: owner.name,
    },
  };
  return item;
};

const constructStudysetMetaDescription = (studySet: StudySet) => {
  const {
    name,
    description,
    terms: {
      edges: termEdges,
    },
  } = studySet;

  const termsWithName = termEdges.slice(0, 2).filter(({
    node: {
      term: {
        name: termName,
      },
    },
  }) => !isEmpty(termName));
  const termsText = termsWithName.map(({
    node: {
      term: {
        name: termName,
      },
    },
  }) => termName).join(', ');
  const defaultDesc = `Study ${name} with flashcards, multiple choice questions, and games. ${termsText ? `Master concepts like ${termsText} and more.` : ''}`;
  const getMetaDescription = () => {
    if (description) {
      return description.length > 100 ? description : defaultDesc;
    }
    return defaultDesc;
  };
  const metaDescription = getMetaDescription();
  return metaDescription.replace(/\n/g, '');
};
const makeStudySetJsonLd = (studySet: StudySet, metaDescription: string) => {
  const {
    name,
    numericId,
    owner,
  } = studySet;

  const url = getCanonicalUrl(tContentType.STUDY_SET, numericId, name);
  const item = {
    '@context': 'https://schema.org',
    '@type': 'Course',
    name,
    description: metaDescription,
    url,
    provider: {
      '@type': 'Organization',
      // eslint-disable-next-line comma-dangle
      name: owner.name
    },
  };
  return item;
};

const makeJsonLdCarousel = (courseJsonLdList: CourseJsonLd[]) => {
  let pos = 1;

  if (courseJsonLdList.length === 1) {
    return courseJsonLdList[0];
  }

  const itemListElement = courseJsonLdList.reduce((acc, item) => {
    const listElement = {
      '@type': 'ListItem',
      position: pos,
      item,
    };
    pos += 1;
    acc.push(listElement);
    return (acc);
  }, []);

  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement,
  };
};

const makeBundleJsonLd = (bundle: Bundle) => {
  const {
    name,
    description,
    publisher,
    numericId,
  } = bundle;

  const url = getCanonicalUrl(tContentType.BUNDLE, numericId, name);
  const item = {
    '@context': 'https://schema.org',
    '@type': 'Course',
    name,
    description,
    url,
    provider: {
      '@type': 'Organization',
      name: publisher.name,
    },
  };
  return item;
};

const makeSearchBoxJsonLd = () => {
  const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: baseUrl,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${baseUrl}topic/{search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  };
  return jsonld;
};

const makeSplashOrgJsonLd = (reviews: string[]) => {
  // Contains Logo and Reviews type Structured data
  const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: baseUrl,
    name: 'Memorang',
    description: 'Personalized study tools that empower learners to master and retain knowledge for any subject',
    logo: `${baseUrl}static/memorang_logo.svg`,
    review: { // TODO: Make this use real review data once that exists
      '@type': 'Review',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: '5',
      },
      author: {
        '@type': 'Person',
        name: 'John Doe',
      },
      reviewBody: reviews[0],
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.9',
      bestRating: '5.0',
      ratingCount: '8',
    },
  };
  return jsonld;
};

const defaultOgImages = [
  {
    url: `${baseUrl}static/og/og_banner.png`,
    width: 1200,
    height: 630,
    alt: 'Memorang Banner',
  },
];

const openGraphFromMeta = (meta: Meta) => {
  const {
    imageProps,
    url,
    title,
    description,
    datePublished,
    dateModified,
  } = meta;

  const openGraph = {
    type: 'website',
    url,
    title,
    site_name: title,
    article: {
      publishedTime: datePublished,
      modifiedTime: dateModified,
    },
    description,
    ...(imageProps ? {
      images: [{
        url: imageProps.imageUrl,
        width: imageProps.imageWidth || 1200,
        height: imageProps.imageHeight || 630,
        alt: imageProps.imageAlt,
      }],
    } : {
      images: [...defaultOgImages],
    }),

  };
  return openGraph;
};
type BreadcrumbsResponse = {
  id: number,
  typ: string,
  name: string
}

const constructJSONLDbreadcrumbs = (
  breadcrumbs: BreadcrumbsResponse[] = [],
) => {
  if (isEmptyList(breadcrumbs)) {
    return {};
  }
  return breadcrumbs.map <any, any>(({
    id,
    typ,
    name,
  }, index) => {
    switch (typ) {
      case 'USR':
        return {
          position: index + 1,
          name,
          item: `${baseUrl}user/${name}`,
        };
      case 'FLD':
        const {
          slug: folderName,
        } = getHrefAndPathForContent(tContentType.FOLDER, id, name);

        return {
          position: index + 1,
          name,
          item: `${baseUrl}folder/${id}/${folderName}`,
        };
      case 'SST':
        const {
          slug: studysetName,
        } = getHrefAndPathForContent(tContentType.STUDY_SET, id, name);
        return {
          position: index + 1,
          name,
          item: `${baseUrl}flashcards/${id}/${studysetName}`,
        };
      case 'QST':
        const {
          slug: questionSetName,
        } = getHrefAndPathForContent(tContentType.QUESTION_SET, id, name);
        return {
          position: index + 1,
          name,
          item: `${baseUrl}questions/${id}/${questionSetName}`,
        };
      default:
        return {
          position: index + 1,
          name,
          item: `${baseUrl}questions/${id}`,
        };
    }
  });
};
export {
  bundleJsonLdQuery,
  bundleCategoriesQuery,
  questionSetQuery,
  studySetQuery,
  folderQuery,
  handleStudyContents,
  makeBundleJsonLd,
  makeQuestionSetJsonLd,
  makeStudySetJsonLd,
  makeJsonLdCarousel,
  makeSearchBoxJsonLd,
  makeSplashOrgJsonLd,
  openGraphFromMeta,
  getCanonicalUrl,
  breadcrumbsQuery,
  constructJSONLDbreadcrumbs,
  constructStudysetMetaDescription,
  questionDetailsQuery,
  bundleNameQuery,
  bundleDetailsCheckoutQuery,
};
