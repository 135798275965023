/* -----------------Third parties--------------- */
import { createTheme } from '@material-ui/core/styles';

// TODO: Replace source path with path in jsconfig file?

import {
  branding,
  darkColors,
  error,
  progress,
  confidence,
  social,
  fontSizes,
  fontWeights,
  fontReusables,
} from 'common/theme/common-theme';

const { primary, secondary } = branding;

const theme = (paletteType) => {
  const themeDefault = createTheme({ palette: { type: paletteType ? 'dark' : 'light' } });
  const themeObject = createTheme({
    typography: {
      useNextVariants: true,
      fontSize: fontSizes.body2,
      fontFamily: ['Open Sans'],
    },
    palette: {
      type: paletteType ? 'dark' : 'light',
      background: {
        paper: paletteType ? darkColors.dark
          : '#fff',
        default: paletteType ? darkColors.background
          : '#e5e5e5',
      },
      darkColors,
      primary,
      secondary,
      error: {
        main: error.main,
      },
      progress: {
        ...progress,
        none: paletteType ? '#757575' : '#ddd',
        skip: darkColors.surface,
      },
      confidence,
      social,
      premium: '#d4af37',
      // TODO:Let's discuss this
      selected: {
        border: '#1e90ff',
        background: 'rgba(155, 213, 255, 0.25)',
      },
      tapColor: '#ccc',

    },
    fontSizes,
    fontWeights,
    overrides: {
      MuiTypography: {
        h6: {
          fontWeight: fontWeights.semiBold,
        },
        h1: {
          fontWeight: fontWeights.bold,
          fontSize: fontSizes.h1,
          lineHeight: 1.2,
        },
        overline: {
          ...fontReusables(paletteType).overline,
          color: themeDefault.palette.text.secondary,
          lineHeight: 'inherit',
        },
        button: {
          ...fontReusables(paletteType).button,
        },
        subtitle2: {
          fontWeight: fontWeights.semiBold,
        },
      },
      MuiBadge: {
        colorSecondary: { color: '#fff' },
      },
      MuiButton: {
        label: { fontWeight: 'bold', letterSpacing: 1.25 },
        containedSecondary: { color: '#fff' },
        outlined: {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
          },
        },
        outlinedSecondary: {
          borderWidth: 2,
          borderColor: branding.secondary.main,
          '&:hover': {
            borderWidth: 2,
          },
        },
      },
      MuiCardHeader: {
        title: { fontSize: fontSizes.h6 },
        subheader: { fontSize: fontSizes.subtitle2 },
      },
      MuiChip: {
        colorSecondary: {
          color: '#fff',
        },
      },
      MuiContainer: {
        root: {
          paddingLeft: 8,
          paddingRight: 8,
        },
      },
      MuiFab: {
        label: {
          letterSpacing: 1.25,
          fontWeight: 'bold',
        },
        secondary: {
          color: '#fff',
        },
      },
      MuiSelected: {},
      MuiTab: {
        root: {
          letterSpacing: 1.25,
          fontSize: 14,
        },
      },
      MuiMenuItem: {
        root: {
          fontSize: 14,
        },
      },
      MuiListSubheader: {
        color: themeDefault.palette.text.secondary,
        root: {
          textTransform: 'uppercase',
          fontSize: fontSizes.caption,
          fontWeight: fontWeights.semiBold,
          letterSpacing: 1.25,
        },
        gutters: {
          paddingLeft: 8,
        },
      },
      MuiStepIcon: {
        root: {
          '&$active': {
            color: branding.secondary.light,
          },
          '&$completed': {
            color: branding.secondary.light,
          },
        },
      },
    },
  });
  return themeObject;
};

export default theme;
